import {
  GetCollectionsV7ResponseCollectionsItemVolume,
  GetCollectionsV7SortBy,
} from '@/lib/reservoir/reservoir.generated';

// Values should match GetCollectionsV7SortBy
export const VolumeSortOption = {
  ALL_TIME: GetCollectionsV7SortBy.allTimeVolume,
  ONE_DAY: GetCollectionsV7SortBy['1DayVolume'],
  SEVEN_DAYS: GetCollectionsV7SortBy['7DayVolume'],
  THIRTY_DAYS: GetCollectionsV7SortBy['30DayVolume'],
} as const;

export type VolumeSortOption = (typeof VolumeSortOption)[keyof typeof VolumeSortOption];

export const volumeSortOptionKeyMap: {
  [key in VolumeSortOption]: keyof GetCollectionsV7ResponseCollectionsItemVolume;
} = {
  [VolumeSortOption.ALL_TIME]: 'allTime',
  [VolumeSortOption.ONE_DAY]: '1day',
  [VolumeSortOption.SEVEN_DAYS]: '7day',
  [VolumeSortOption.THIRTY_DAYS]: '30day',
};
