import { Box, HStack, StackProps, useRadio, useRadioGroup, UseRadioProps } from '@chakra-ui/react';
import { rem } from 'polished';
import { PropsWithChildren } from 'react';

const RadioFilter = ({
  title,
  ...props
}: PropsWithChildren<{ title?: string } & UseRadioProps>) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box title={title} as="label" role="group">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        display="inline-flex"
        alignItems="center"
        justifyContent="space-around"
        px="space.8"
        minW={rem(30)}
        h={rem(30)}
        lineHeight={1}
        bg="gray.2"
        color="white"
        fontSize="sm"
        rounded="sm"
        transition="all .2s ease"
        fontWeight="medium"
        _hover={{
          bg: 'gray.4',
        }}
        _focus={{
          bg: 'gray.4',
        }}
        _checked={{
          bg: 'white',
          color: 'black',
        }}
        _groupFocusWithin={{ boxShadow: 'outline' }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

type Option<OptionType> = { label: string | JSX.Element; value: OptionType; title?: string };

type Props<OptionType> = Omit<StackProps, 'onChange'> & {
  name: string;
  options: Option<OptionType>[];
  defaultValue: OptionType;
  onChange(nextValue: OptionType): void;
};

export const ButtonRadioGroup = <OptionType extends string>({
  name,
  defaultValue,
  options,
  onChange,
  ...stackProps
}: Props<OptionType>) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    onChange,
  });

  const group = getRootProps();

  return (
    <HStack {...group} spacing="space.4" {...stackProps}>
      {options.map(({ label, value, title }) => {
        const radio = getRadioProps({ value });

        return (
          <RadioFilter
            key={value}
            title={title || (typeof label === 'string' ? label : '')}
            {...radio}
          >
            {label}
          </RadioFilter>
        );
      })}
    </HStack>
  );
};
