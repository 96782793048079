import { CollectionInsightsItemMobile } from '@/components/features/CollectionInsights/CollectionInsightsItem/CollectionInsightsItemMobile';
import { Sticky } from '@/config/sticky-layout';
import { useCollectionsInfinite } from '@/hooks/use-collections-infinite';
import { Box, Button, Divider, Flex, Table, Tbody, Text, Thead, Tr } from '@chakra-ui/react';
import { isDefined } from '@onbeam/utils';
import { ButtonRadioGroup, TableEmptyRows, TableHeader, TableSkeletonRows } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { FC, PropsWithChildren, useState } from 'react';
import { CollectionInsightsItemDesktop } from './CollectionInsightsItem/CollectionInsightsItemDesktop';
import { VolumeSortOption } from './types';

type Props = PropsWithChildren & {
  limitRows?: number;
  disableFilters?: boolean;
  disablePagination?: boolean;
  initialSort?: VolumeSortOption;
};

export const CollectionInsights: FC<Props> = ({
  limitRows,
  disableFilters,
  disablePagination,
  initialSort = VolumeSortOption.ALL_TIME,
  children,
}) => {
  const { t } = useTranslation('insights');

  const [volumeSortOption, setVolumeSortOption] = useState(initialSort);

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useCollectionsInfinite({
      params: { sortBy: volumeSortOption, limit: limitRows },
    });

  const collections = data?.pages?.flatMap(page => page.collections).filter(isDefined);

  const periodFilterOptions = [
    VolumeSortOption.ALL_TIME,
    VolumeSortOption.ONE_DAY,
    VolumeSortOption.SEVEN_DAYS,
    VolumeSortOption.THIRTY_DAYS,
  ].map(value => ({
    label: t(`filters.period.options.${value.toLowerCase()}`),
    value,
  }));

  return (
    <Flex flexDir="column" alignItems="center">
      {!disableFilters && (
        <Sticky w="full" element="filter-bar" zIndex="sticky-layout">
          <Box bg="blackBg" pt="space.12" mb="space.8" pb={{ base: 'space.8', md: 0 }}>
            <Flex alignItems="center" justifyContent="space-between" minH={rem(40)}>
              <ButtonRadioGroup
                name="period-filter"
                options={periodFilterOptions}
                defaultValue={volumeSortOption}
                onChange={volumeSort => setVolumeSortOption(volumeSort)}
              />
            </Flex>
            <Divider mt="space.8" mb={0} display={{ base: 'none', md: 'block' }} />
          </Box>
        </Sticky>
      )}

      <Table
        variant="unstyled"
        position="relative"
        display={{ base: 'none', md: 'table' }}
        style={{ borderCollapse: 'separate', borderSpacing: `0 ${rem(8)}` }}
      >
        <Sticky
          as={Thead}
          element="table-header"
          zIndex="sticky-layout-contained"
          stuck={{
            animate: {
              boxShadow: 'sticky.table-header',
            },
          }}
        >
          <Tr _hover={{}} borderBottom="none">
            <TableHeader pl={0} width="40%">
              {t('table.headers.item')}
            </TableHeader>
            <TableHeader>{t('table.headers.floor')}</TableHeader>
            <TableHeader>{t('table.headers.offer')}</TableHeader>
            <TableHeader>
              {disableFilters &&
                periodFilterOptions.find(option => option.value === volumeSortOption)?.label}{' '}
              {t('table.headers.volume')}
            </TableHeader>
            <TableHeader display={{ base: 'none', lg: 'table-cell' }}>
              {t('table.headers.day-change')}
            </TableHeader>
            <TableHeader display={{ base: 'none', lg: 'table-cell' }}>
              {t('table.headers.week-change')}
            </TableHeader>
            <TableHeader display={{ base: 'none', lg: 'table-cell' }}>
              {t('table.headers.supply')}
            </TableHeader>
          </Tr>
        </Sticky>
        <Tbody>
          {isLoading ? (
            <TableSkeletonRows rows={limitRows || 20} cols={7} height={rem(80)} />
          ) : (
            collections?.map((collection, index) => (
              <CollectionInsightsItemDesktop
                key={collection.id}
                index={index + 1}
                volumeSortOption={volumeSortOption}
                {...collection}
              />
            ))
          )}
        </Tbody>
      </Table>

      <Box w="full" display={{ md: 'none' }}>
        {isLoading ? (
          <TableSkeletonRows
            rows={limitRows || 20}
            height={{ base: rem(185), sm: rem(135) }}
            isMobile
          />
        ) : (
          collections?.map((collection, index) => (
            <CollectionInsightsItemMobile
              key={collection.id}
              index={index + 1}
              volumeSortOption={volumeSortOption}
              {...collection}
            />
          ))
        )}
      </Box>

      {!isLoading && !collections?.length && (
        <>
          <Text textAlign="center" fontSize="md" color="gray.5" my="space.32">
            {t('table.empty')}
          </Text>
          <TableEmptyRows />
        </>
      )}

      {!disablePagination && hasNextPage && (
        <Button
          mt="space.16"
          variant="secondary"
          onClick={() => fetchNextPage()}
          isLoading={isFetchingNextPage}
        >
          {t('table.load-more')}
        </Button>
      )}

      {children}
    </Flex>
  );
};
