import { ChangePercentageTableCell, PriceTableCell } from '@/components/shared/Table';
import { useChainCurrency } from '@/hooks/use-chain-currency';
import { CollectionType } from '@/hooks/use-collections';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useENSResolver } from '@/hooks/useENSResolver';
import { routes } from '@/utils/routes';
import { Flex, Td, Text, Tooltip, Tr } from '@chakra-ui/react';
import { formatAddress, formatNumber } from '@onbeam/utils';
import { TableRowMediaItem } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { VolumeSortOption, volumeSortOptionKeyMap } from '../types';

type Props = {
  index: number;
  volumeSortOption: VolumeSortOption;
} & CollectionType;

export const CollectionInsightsItemDesktop = ({
  index,
  id,
  name,
  image,
  volume,
  floorAsk,
  topBid,
  volumeSortOption,
  volumeChange,
  tokenCount,
  creator,
}: Props) => {
  const { t } = useTranslation('insights');
  const chain = useMarketplaceChain();
  const volumeSortOptionKey = volumeSortOptionKeyMap[volumeSortOption];
  const currency = useChainCurrency(chain.id);
  const { displayName } = useENSResolver(creator);

  return (
    <Tr role="group">
      <Td px="space.16">
        <Flex alignItems="center">
          <Flex
            rounded="full"
            w={rem(22)}
            h={rem(22)}
            flexShrink={0}
            alignItems="center"
            justifyContent="center"
            border="1px solid"
            borderColor="border"
            color="gray.1"
            fontSize="xs"
            fontWeight="medium"
            mr="space.16"
          >
            {index}
          </Flex>
          <TableRowMediaItem
            href={routes.collections.detail(id ?? '', chain.routePrefix)}
            title={formatAddress(name)}
            imageSrc={image}
            subtitle={
              <Text fontSize="sm" color="gray.5" noOfLines={1} mr="space.4">
                {displayName}
              </Text>
            }
            rounded="full"
            coverImage
          />
        </Flex>
      </Td>
      <PriceTableCell
        amount={floorAsk?.price?.amount?.decimal}
        usdPrice={floorAsk?.price?.amount?.usd}
        currency={floorAsk?.price?.currency?.symbol}
      />
      <PriceTableCell
        amount={topBid?.price?.amount?.decimal}
        usdPrice={topBid?.price?.amount?.usd}
        currency={topBid?.price?.currency?.symbol}
      />
      <PriceTableCell amount={volume?.[volumeSortOptionKey]} currency={currency.symbol} />
      <ChangePercentageTableCell
        value={volumeChange?.['1day']}
        display={{ base: 'none', lg: 'table-cell' }}
      />
      <ChangePercentageTableCell
        value={volumeChange?.['7day']}
        display={{ base: 'none', lg: 'table-cell' }}
      />
      <Td display={{ base: 'none', lg: 'table-cell' }}>
        <Tooltip
          label={t('table.token-count', {
            count: formatNumber(tokenCount, { notation: 'standard' }),
          })}
          fontSize="md"
          gutter={16}
          placement="top"
          hasArrow
          arrowSize={8}
        >
          {formatNumber(tokenCount)}
        </Tooltip>
      </Td>
    </Tr>
  );
};
