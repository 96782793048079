import {
  getGetCollectionsV7InfiniteQueryOptions,
  useGetCollectionsV7Infinite,
  type GetCollectionsV7Params,
} from '@/lib/reservoir/reservoir.generated';
import { type ReservoirChain } from '@/utils/chains';
import { useMarketplaceChain } from './use-marketplace-chain';

const defaultParams: GetCollectionsV7Params = {
  includeMintStages: true,
  useNonFlaggedFloorAsk: true,
  excludeSpam: true,
  excludeEmptyCollections: true,
};

type Options = {
  params?: GetCollectionsV7Params;
};

const getCollectionsInfinite = (chain: ReservoirChain, options?: Options) => {
  return getGetCollectionsV7InfiniteQueryOptions(
    { ...defaultParams, ...options?.params },
    {
      query: {
        queryKey: ['/collections/v7', chain.id, options?.params?.sortBy, options?.params?.limit],
      },
      request: {
        baseURL: chain.reservoirBaseUrl,
        headers: {
          'x-api-key': chain.reservoirApiKey,
        },
      },
    },
  );
};

const useCollectionsInfinite = (options: Options = {}) => {
  const chain = useMarketplaceChain();

  const request = useGetCollectionsV7Infinite(
    { ...defaultParams, ...options.params },
    {
      query: {
        getNextPageParam: lastPage => lastPage.continuation,
        queryKey: ['/collections/v7', chain.id, options.params?.sortBy, options.params?.limit],
      },
      request: {
        baseURL: `${process.env.NEXT_PUBLIC_HOST_URL}${chain.proxyApi}`,
      },
    },
  );

  return request;
};

export { getCollectionsInfinite, useCollectionsInfinite };
