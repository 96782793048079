import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { getSupportedChainById } from '@/utils/getChain';
import { zeroAddress } from 'viem';
import { mainnet, sepolia } from 'wagmi/chains';

export const useChainCurrency = (chainId?: number) => {
  const marketplaceChain = useMarketplaceChain();

  const chain = chainId ? getSupportedChainById(chainId) || marketplaceChain : marketplaceChain;

  const ETHChains: number[] = [mainnet.id, sepolia.id];

  if (!chain?.nativeCurrency || ETHChains.includes(chain.id)) {
    return {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
      address: zeroAddress,
      chainId: chain?.id || mainnet.id,
    };
  } else {
    return {
      ...chain.nativeCurrency,
      address: zeroAddress,
      chainId: chain.id,
    };
  }
};
