import { useChainCurrency } from '@/hooks/use-chain-currency';
import { CollectionType } from '@/hooks/use-collections';
import { useMarketplaceChain } from '@/hooks/use-marketplace-chain';
import { useENSResolver } from '@/hooks/useENSResolver';
import { routes } from '@/utils/routes';
import { Box, Divider, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import { formatAddress, formatNumber, formatTokenAmount } from '@onbeam/utils';
import { TableRowMediaItem } from '@sphere/ui';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { VolumeSortOption, volumeSortOptionKeyMap } from '../types';

type Props = {
  index: number;
  volumeSortOption: VolumeSortOption;
} & CollectionType;

export const CollectionInsightsItemMobile = ({
  index,
  id,
  name,
  image,
  creator,
  floorAsk,
  topBid,
  volumeSortOption,
  volume,
  tokenCount,
}: Props) => {
  const { t } = useTranslation('insights');
  const chain = useMarketplaceChain();
  const volumeSortOptionKey = volumeSortOptionKeyMap[volumeSortOption];
  const currency = useChainCurrency(chain.id);
  const { displayName } = useENSResolver(creator);

  return (
    <Box bg="gray.2" p="space.16" rounded="radius.12" mb="space.8">
      <Flex alignItems="center">
        <Flex
          rounded="full"
          w={rem(22)}
          h={rem(22)}
          flexShrink={0}
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          borderColor="border"
          color="gray.1"
          fontSize="xs"
          fontWeight="medium"
          mr="space.16"
        >
          {index}
        </Flex>
        <TableRowMediaItem
          href={routes.collections.detail(id ?? '', chain.routePrefix)}
          title={formatAddress(name)}
          imageSrc={image}
          subtitle={
            <Text fontSize="sm" color="gray.5" noOfLines={1} mr="space.4">
              {displayName}
            </Text>
          }
          rounded="full"
          coverImage
        />
      </Flex>
      <Divider my="space.8" />
      <SimpleGrid columns={{ base: 2, sm: 4 }} gap="space.8">
        <Box>
          <Text color="gray.5" fontSize="xs">
            {t('table.headers.floor')}
          </Text>
          {formatTokenAmount(floorAsk?.price?.amount?.decimal, {
            symbol: floorAsk?.price?.currency?.symbol,
            fallback: '-',
          })}
        </Box>
        <Box>
          <Text color="gray.5" fontSize="xs">
            {t('table.headers.offer')}
          </Text>
          {formatTokenAmount(topBid?.price?.amount?.decimal, {
            symbol: topBid?.price?.currency?.symbol,
            fallback: '-',
          })}
        </Box>
        <Box>
          <Text color="gray.5" fontSize="xs">
            {t('table.headers.volume')}
          </Text>
          {formatTokenAmount(volume?.[volumeSortOptionKey], {
            symbol: currency.symbol,
            fallback: '-',
          })}
        </Box>
        <Box>
          <Text color="gray.5" fontSize="xs">
            {t('table.headers.supply')}
          </Text>
          {formatNumber(tokenCount)}
        </Box>
      </SimpleGrid>
    </Box>
  );
};
